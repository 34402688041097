.ab-btn {
  margin-top: 20px !important;
}

.rotate-circle {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 33%;
  top: 40%;
  animation: rotation 5s linear infinite;
}

.rotate-circle-2 {
  position: absolute;
  width: 218px;
  height: 218px;
  left: 75%;
  top: 61%;
  animation: rotation-2 5s linear infinite;
}

.rotate-circle-3 {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 67%;
  top: 10%;
  animation: rotation-2 10s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation-2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

@keyframes rotation-3 {
  from {
    width: 0;
    height: 0;
    transform: rotate(0deg);
  }

  to {
    width: 100px;
    height: 100px;
    transform: rotate(-359deg);
  }
}

.css-1m9128y {
  text-align: left !important;
  z-index: 2 !important;
  position: relative;
}

.css-tlq4da-MuiButtonBase-root-MuiIconButton-root {
  color: #5f52dd !important;
}

.css-hegdou-MuiButtonBase-root-MuiButton-root {
  background: linear-gradient(195deg, #49a3f1, #5f52dd) !important;
}

.css-1l7c0cy img {
  height: 350px !important;
}

.imggood {
  background-color: #333333;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.highlySecure {
  background-color: #ff5d4c;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.fastdevelopment {
  background-color: #6466b2;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.hd_se_section .hd_se_an {
  color: rgb(0 0 0) !important;
  box-shadow: 0px 11px 7px -3px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  height: 100%;
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(from var(--border-angle), rgb(255, 255, 255), rgb(255, 255, 255), 50%, rgb(255, 255, 255)), conic-gradient(from var(--border-angle), transparent 20%, #49a3f1, #5f52dd);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 10s linear infinite;
  -moz-animation: bg-spin 10s linear infinite;
  -o-animation: bg-spin 10s linear infinite;
  animation: bg-spin 10s linear infinite;
}

@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@-moz-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@-o-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.hd_se_section .hd_se_an:hover {
  box-shadow: 0px 17px 7px -6px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}


.it_sc_one {
  background-color: #fad4e4 !important;
  position: relative;
}

.it_sc_one:hover {
  background-color: #ffe4ef !important;
  box-shadow: 0px 6px 18px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.it_sc_sec {
  background-color: #cbd9fb !important;
  position: relative;
}

.it_sc_sec:hover {
  background-color: #d6e1fa !important;
  box-shadow: 0px 6px 18px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.hd_se_section1 a {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  border: 4px solid;
  border-image-slice: 1;
  border-width: 2px;
  width: 130px;
  padding: 4px 9px;
  margin: 15px 0px;
  text-align: center;
  border-image-source: linear-gradient(to left, #49a3f1, #5f52dd);
  display: block;
  color: #050d54;
  text-transform: uppercase;
  background: linear-gradient(195deg, #49a3f100, #6052dd00) !important;
  transition: background 10s;
}

.hd_se_section1 a:hover {
  background: linear-gradient(267deg, #49a3f1, #5f52dd) !important;
  color: #ffffff;
  box-shadow: -1rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2) !important;
}

img.scximg {
  width: 132px;
  position: absolute;
  bottom: 0;
  right: 29px;
}

.minxxv {
  height: fit-content;
  padding-bottom: 30px;
  width: 100% !important;
  margin-top: 64px;
  display: inline-block;
  margin-bottom: 32px;
  background: linear-gradient(151deg, #d6e3ff, #ffffff);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  -o-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 14%
  }

  50% {
    background-position: 100% 87%
  }

  100% {
    background-position: 0% 14%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 14%
  }

  50% {
    background-position: 100% 87%
  }

  100% {
    background-position: 0% 14%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 14%
  }

  50% {
    background-position: 100% 87%
  }

  100% {
    background-position: 0% 14%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 14%
  }

  50% {
    background-position: 100% 87%
  }

  100% {
    background-position: 0% 14%
  }
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(267deg, #49a3f1, #5f52dd) !important;
  outline: none;
  border: none;
  border-radius: 2px;
}

body::-webkit-scrollbar-track {
  background: #050d56;
  outline: none;
  border: none;
}

.em_bar {
  background: #9B50DB none repeat scroll 0 0;
  height: 2px;
  margin: 0 auto 5px;
  width: 62px;
}

.em_bar_bg {
  height: 5px;
  width: 90px;
  background: #cbbded;
  margin: 20px auto;
  position: relative;
  border-radius: 30px;
}

.em_bar_bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #5d51dc;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

.uniqcl {
  color: #5d51dc !important;
}

.man_arrow img {
  margin: 70px 50px 0px 0px;
}

.containerBox.boxed {
  position: relative;
  left: -80px;
}

@media screen and (max-width: 992px) {
  .containerBox.boxed {
    position: relative;
    left: 0px;
  }
}

.containerBox.boxed1 {
  position: relative;
}

.div-box {
  position: relative;
  padding-left: 50px;
}

span.innner-span {
  z-index: 2;
  width: 120px;
  height: 120px;
  border: 6px solid #3733a9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-bottom: 6px solid #ffffff00;
  position: absolute;
  left: 5px;
  bottom: -2px;
  background: white;
  transform: rotate(-23deg);
  border-right: 6px solid #ffffff00;
  transition: transform .7s ease-in-out;
}

.spanicon-1 {
  position: relative;
  top: 94px;
  z-index: 3;
  left: -20px;
}

.spanicon-1 div {
  margin: 0px !important;
}

.headingInner {
  margin: 5px 0% !important;
  display: block;
  position: absolute;
  left: 15%;
}

span.innerSpan2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 550px;
  height: 117px;
  padding: 6px 20px;
  padding-right: 25px;
  background: #d6e3ff;
  border-radius: 0px 55px 55px 0px;
  box-shadow: 3px 5px 10px -6px #3657cf78;
}

.flowPara {
  width: 350px;
  position: absolute;
  bottom: 6px;
}

span.innerSpan3 {
  background: #3733a9;
  padding: 15px 25px;
  color: white;
  font-weight: 700;
  border-radius: 20px 20px 0 0;
  display: flex;
  width: 170px;
  justify-content: flex-end;
  font-size: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.containerBox:hover span.innner-span {
  transform: rotate(337deg);
}

.containerBox:hover span.innerSpan2 {
  box-shadow: 3px 7px 13px -3px #3657cf78;
}

.lottie_file {
  margin: 0px !important;
}

img.usertestimonial {
  border-radius: 50%;
}

@media screen and (max-width: 992px) {
  .containerBox.boxed {
    position: relative;
    left: 0px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .rotate-circle-2 {
    left: 60%;
  }

  .headingInner {
    font-size: 14px !important;
  }

  span.innner-span {
    z-index: 2;
    width: 90px;
    height: 90px;
  }

  span.innerSpan3 {
    padding: 6px 7px;
    border-radius: 20px 12px 0 0;
    width: 96px;
    font-size: 14px;
  }

  .containerBox.boxed {
    position: relative;
    left: 0px;
  }

  span.innerSpan2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 86px;
  }

  .headingInner {
    font-size: .8rem;
  }

  .spanicon-1 {
    position: relative;
    top: 68px;
    left: -25px;
  }

  .spanicon-1 div[role="button"] {
    width: 50px !important;
    height: 50px !important;
  }

  .flowPara {
    width: 161px;
    position: absolute;
    bottom: 6px;
    font-size: 9px !important;
  }
  .css-1kti1s {
    flex-direction: column;
  }
  .css-1qczu1c {
    float: unset !important;
    width: 100% !important;
  }
  .css-1l0g8il {
    background-position: left bottom !important;
  }
  .css-1l7c0cy img {
    height: 226px !important;
  }
  .css-1m9128y {
    text-align: center !important;
  }
}

@media screen and (max-width: 480px) {
  .rotate-circle {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 6%;
    top: 18%;
    animation: rotation 5s linear infinite;
  }

  span.innner-span {
    z-index: 2;
    width: 90px;
    height: 90px;
  }

  span.innerSpan3 {
    padding: 6px 7px;
    border-radius: 20px 12px 0 0;
    width: 96px;
    font-size: 14px;
  }

  .containerBox.boxed {
    position: relative;
    left: 0px;
  }

  span.innerSpan2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 86px;
  }

  .headingInner {
    font-size: .8rem;
  }

  .spanicon-1 {
    position: relative;
    top: 68px;
    left: -25px;
  }

  .flowPara {
    width: 161px;
    position: absolute;
    bottom: 6px;
    font-size: 9px !important;
  }

  .spanicon-1 div[role="button"] {
    width: 50px !important;
    height: 50px !important;
  }
  .testinfo{
   font-size: 10px !important;
  }
  .css-1l0g8il {
    background-position: left bottom !important;
  }

  .css-1kti1s {
    flex-direction: column;
  }
  .css-1qczu1c {
      float: unset !important;
      width: 100% !important;
  }
  .css-1l7c0cy img {
      height: 226px !important;
  }
  .css-1m9128y {
    text-align: center !important;
  }
  .rotate-circle-2 {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 57%;
    top: 58%;
    animation: rotation-2 5s linear infinite;
  }

  .rotate-circle-3 {
    position: absolute;
    width: 125px;
    height: 125px;
    left: 50%;
    top: 10%;
    animation: rotation-2 10s linear infinite;
  }

  .man_arrow {
    display: none;
  }
}

.ratingset {
  margin: auto;
}


.containers {
  margin: 64px 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(91deg, #CBC6FF 0%, #6357E0 100%);
  height: 108vh;
}

.containers::before,
.containers::after {
  position: absolute;
  width: 350px;
  height: 350px;
  content: "";
  border-radius: 54% 46% 42% 58%/60% 58% 42% 40%;
  background-image: linear-gradient(45deg, #ab49de, #5b41f2);
  animation: vawe 5s linear infinite;
}

.containers::before {
  top: -10%;
  right: -10%;
}

.containers::after {
  bottom: -10%;
  left: -15%;
}

.containers .dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.containers .dots span {
  position: absolute;
  border-radius: 50%;
  background-image: linear-gradient(45deg, #ab49de, #5b41f2);
  border-radius: 38% 62% 55% 45%/32% 53% 47% 68%;
  animation: vawe 7s linear infinite;
}

.containers .dots span:nth-child(1) {
  top: 40%;
  left: calc(100% - 360px);
  width: 75px;
  height: 75px;
}

.containers .dots span:nth-child(2) {
  top: 15px;
  left: 180px;
  width: 50px;
  height: 50px;
  border-radius: 38% 62% 33% 67%/60% 53% 47% 40%;
  transform: rotate(90deg);
}

.containers .dots span:nth-child(3) {
  right: 55px;
  bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 38% 62% 55% 45%/52% 53% 47% 48%;
}

.containers .dots span:nth-child(4) {
  bottom: 50%;
  left: 20%;
  width: 66px;
  height: 63px;
  border-radius: 38% 62% 55% 45%/52% 53% 47% 48%;
}

.containers .dots span:nth-child(5) {
  right: 280px;
  bottom: 80px;
  width: 25px;
  height: 25px;
  border-radius: 38% 62% 55% 45%/52% 53% 47% 48%;
}

.containers .dots span:nth-child(6) {
  top: 6%;
  left: calc(100% - 400px);
  width: 25px;
  height: 25px;
}

.resize {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 2;
  text-transform: uppercase;
  font-size: 1rem;
  color: #fefefe;
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1em;
  text-align: center;
}

.content h1 {
  color: #ffffff !important;
  font-size: 6vw;
}

.content h2 {
  color: #ffffff !important;
  font-size: 3.5vw;
}

@media screen and (max-width: 600px) {
  .content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 5rem;
    text-align: center;
  }

  .content h1 {
    color: #ffffff !important;
    font-size: 12vw;
  }

  .content h2 {
    color: #ffffff !important;
    font-size: 7vw;
  }

  .bounsImg {
    grid-template-columns: 10vw 6vw 10vw !important;
  }
}

@keyframes vawe {
  20% {
    border-radius: 45% 55% 62% 38%/53% 51% 49% 47%;
  }

  40% {
    border-radius: 45% 55% 49% 51%/36% 51% 49% 64%;
  }

  60% {
    border-radius: 60% 40% 57% 43%/47% 62% 38% 53%;
  }

  80% {
    border-radius: 60% 40% 32% 68%/38% 36% 64% 62%;
  }
}

.mansel,
.womansel {
  animation: bounce 2s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.05, 0.05, 0.05, 0.05);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 20px, 0);
  }
}

.btn-bg-color {
  background: linear-gradient(183deg, #49a3f1, #5f52dd) !important;
  color: #ffffff !important;
}

.btn-bg-color:hover {
  box-shadow: -1rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2) !important;
}

.ocean {
  /* change the height of the waves here */
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("../images/about-us/Union.png");
  position: absolute;
  background-repeat: no-repeat;
  width: 400%;
  background-position: top;
  background-size: cover;
  height: 100%;
  animation: wave 10s -10s linear infinite;
  transform: translate3d(0, 0, 0);
}


@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.tab_header .MuiTabs-root {
  background: #ffffff;
  box-shadow: 0px 12px 20px 0px #dadadad9;
}

.tab_header button {
  display: grid !important;
  justify-items: center;
  padding: 10px;
}

.tab_header svg {
  width: 100%;
}

.tab_header .MuiTabs-flexContainer {
  z-index: 8;
}

.tab_header .MuiTabs-indicator {
  background: linear-gradient(270deg, #49a3f19e, #5f52dd94) !important;
}

.team_infomation {
  background-color: #ffffff !important;
  border-radius: 2%;
  padding: 5%;
}

.hd_text {
  color: #fff !important;
}

.header-weblink {
  color: #fff !important;
}

.comwork_f_o .css-1m9128y {
  text-align: center !important;
}

.tl_sc {
  background-image: url("../images/bg-tl.png") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.scr_im_se div span::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}

.scr_im_se div span::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.anbgx::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}

.anbgx::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-82%);
  }
}

@keyframes scrolls {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-70%);
  }
}